import { SESSION_LENGTH_MINUTES } from '@/constants'

export default {

  setUserData (state, userData) {
    state.userData = userData

    this.commit("setSessionExpiredTimeoutId")
  },

  setSessionExpiredTimeoutId (state) {
    if (state.sessionExpiredTimeoutId) {
      clearTimeout(state.sessionExpiredTimeoutId)
    }

    // logged-in user session has authToken & userData,
    // whb session has authToken only
    if (!state.userData || !state.authToken) {
      return
    }

    // 5 seconds  is added to allow for a graceful keepalive without flickering between 10m
    state.sessionExpiredTimeoutId = setTimeout(() => {
      this.commit('setSessionExpired', true);
    }, SESSION_LENGTH_MINUTES * 60 * 1000 + 5000);
  },

  setAuthToken (state, authToken) {
    state.authToken = authToken
  },

  setShowWelcomePopup (state, showWelcomePopup) {
    state.showWelcomePopup = showWelcomePopup
  },

  setSetupCompleted (state, setupCompleted) {
    state.userData.setup_completed = setupCompleted
  },

  setSessionExpired (state, expired) {
    // expired window will only open when session was not expired yet, but will be set now
    state.sessionExpired = expired
  }
}
